import React from 'react';
import logo from './assets/images/svg/logo-loans.svg';
import {Button, Heading, Image, PrimaryButton, PrimaryHeading} from "./components";
import {HeadingType} from "./components/common/typography/headings/Heading/Heading";
import {PrimaryButtonColor} from "./components/common/buttons/decorators/PrimaryButton/PrimaryButton";
import styles from "./App.module.scss";

const App = () => {
  return (
    <>
      <div className={styles.app__mainSection}>
        <div className={styles.app__container}>
          <Image src={logo} alt={'logo'} className={styles.app__logo}/>
          <PrimaryHeading>
            <Heading headingType={HeadingType.H1} className={styles.app__title}>
              Объединяем бизнес и инвестиции для совместного роста
            </Heading>
          </PrimaryHeading>
          <p>Инвестируйте в бизнесы сегодня</p>
          <PrimaryButton color={PrimaryButtonColor.GREEN}>
            <Button className={styles.app__button}>Начать инвестировать</Button>
          </PrimaryButton>
        </div>
      </div>
      <div className={styles.app__secondContainer}>
        <div className={styles.app__secondHeadingContainer}>
          <Heading headingType={HeadingType.H2}>
            Выгодное вложение денежных средств
          </Heading>
          <p>Кономика - займы для бизнеса связывает инвесторов с предпринимателями, нуждающимися в быстром
            финансировании. Инвесторы получают доход, а предприниматели - доступ к быстрым займам.</p>

        </div>
        <div className={styles.app__video}>
        </div>
        <div className={styles.app__blockContainer}>
          <div className={styles.app__grayBlock}>
            <Heading headingType={HeadingType.H3}>Получите заем для развития бизнеса по выгодной ставке за считанные
              дни</Heading>
            <p>Распределяйте средства в разные проекты, чтобы диверсифицировать портфель</p>
          </div>
          <div className={styles.app__greenBlock}>
            <Heading headingType={HeadingType.H3}>Ставка от 30% годовых – лучше, чем классические предложения
              банков</Heading>
            <p>Простота оформления – минимум документов, деньги на счет за 3–5 дней. Поддержка роста вашего бизнеса</p>
          </div>
          <div className={styles.app__grayBlock}>
            <Heading headingType={HeadingType.H3}>Ваши инвестиции – ваша стабильная доходность</Heading>
            <p>Вкладывайте в реальные бизнес-проекты с гарантией прозрачности</p>
          </div>
          <div className={styles.app__whiteBlock}>
            <Heading headingType={HeadingType.H3}>Связаться с нами</Heading>
            <p>Получите бесплатную консультацию от нашего инвестиционного менеджера</p>
          </div>
          <div className={styles.app__greenBlock}>
            <Heading headingType={HeadingType.H3}>Доходность выше банковских депозитов</Heading>
            <p>Простота и контроль – выбирайте проекты, отслеживайте их реализацию. Надежность
              и строгий отбор заемщиков</p>
          </div>
        </div>
      </div>
    </>

  );
};

export default App;
