import React, {useContext} from "react";
import {DetailedProjectContext} from "../DetailedProjectContext/DetailedProjectContext";
import ProjectDropdown from "../ProjectDropdown";
import {useWindowWidth} from "../hooks";
import {Block} from "../../../layout";
import DetailedProjectNavigation from "../DetailedProjectNavigation";
import {RenderPaymentSchedule} from "../RenderPaymentSchedule";

const ProjectPaymentSchedule = () => {
  const paymentSchedule = useContext(DetailedProjectContext).paymentSchedule;
  const isMobile = useWindowWidth();

  return (
    <div>
      {isMobile ?
        <ProjectDropdown header="График погашения">
          <RenderPaymentSchedule paymentSchedule={paymentSchedule} />
        </ProjectDropdown>
        : <Block>
          <Block.Content>
            <Block.Header withPadding className="detailed-project__header">
              <DetailedProjectNavigation/>
            </Block.Header>
            <Block.Body>
              <RenderPaymentSchedule paymentSchedule={paymentSchedule}/>
            </Block.Body>
          </Block.Content>
        </Block>}
    </div>
  );
};

export default ProjectPaymentSchedule;