import React, {useContext, useEffect, useState} from "react";
import styles from './ProjectMain.module.scss';
import {DetailedProjectContext} from "../DetailedProjectContext/DetailedProjectContext";
import {OrderDetails, translateProjectStatus} from "../../../../api-client";
import {Money} from "../../../common";
import {createOrdersProvider} from "../../../../di";
import {useErrorCallback, useLoading} from "../../../../hooks";
import apiClientConfig from "../../../../configs/app";
import {Environment} from "../../../../utils";
import ProjectMainStats from "../ProjectMainStats";
import {formatDate} from "../../../../formatters";

const ProjectMain = () => {
  const project = useContext(DetailedProjectContext).project;
  const errorCallback = useErrorCallback();
  const [loading, withLoading] = useLoading();
  const [order, setOrder] = useState<OrderDetails>();

  useEffect(() => {
    const callback = async () => {
      try {
        const provider = await createOrdersProvider();
        const order = await provider.getOne(project.uuid!);
        setOrder(order);
      } catch (error) {
        if (error instanceof Error) {
          await errorCallback(error);
        }
      }
    };
    (async () => {
      await withLoading(callback);
    })();
  }, []);


  const devAndTest = (apiClientConfig.applicationEnvironment === Environment.dev)
        || (apiClientConfig.applicationEnvironment === Environment.test);

  if (loading) return <div>Загружаем...</div>;
  return (<>
    <div className={styles.detailedProjectMain}>
      <div className={styles.detailedProjectMain__top}>
        <div className={styles.detailedProjectMain__status}>
          <div className={styles.detailedProjectMain__code}>
            {project.paymentCode}
          </div>
          <div>
            {translateProjectStatus(project.status)}
          </div>
          <div className={styles.detailedProjectMain__code}>
            Тип: займ
          </div>
        </div>
        <div className={styles.detailedProjectMain__classContainer}>
          <div className={styles.detailedProjectMain__surety}>
            Поручительство
          </div>
          <div className={styles.detailedProjectMain__earlyRepayment}>
            Залог
          </div>
          <div className={styles.detailedProjectMain__rating}>
            Рейтинг AA+BB-
          </div>
        </div>
      </div>
      <div className={styles.detailedProjectMain__project}>
        <div>
          <p className={styles.detailedProjectMain__name}>
            {project.name[0].text}
          </p>
          <p className={styles.detailedProjectMain__info}>
            Срок действия инвестиционного предложения
            с {formatDate(project.investOfferDate)} до {formatDate(project.maturityDate)}
          </p>
        </div>
        {devAndTest && <div>
          <div className={styles.detailedProjectMain__oredrsSum}>
            <span className={styles.detailedProjectMain__oredrsSum__text}>Сумма ваших заявок: </span>
            <Money money={order?.investment || {amount: 0, currencyCode: 'RUB'}}/>
          </div>
        </div>}
      </div>

    </div>
    <ProjectMainStats/>
  </>
  );
};

export default ProjectMain;