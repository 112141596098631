import React, {FC, ImgHTMLAttributes} from "react";
import logoBlack from '../../../../assets/images/svg/logo-black.svg';
import logoWhite from '../../../../assets/images/svg/logo-white.svg';
import logoGreen from '../../../../assets/images/svg/logo-new.svg';
import logoYellow from '../../../../assets/images/svg/logo-yellow.svg';
import Image from "../Image";

export enum LogoType {
    GREEN = logoGreen,
    WHITE = logoWhite,
    BLACK = logoBlack,
    YELLOW = logoYellow
}

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
    type: LogoType
}

const LogoImage: FC<Props> = ({type, ...props}) => {
  return (
    <Image {...props} src={type.toString()} alt={"Лого"} />
  );
};

export default LogoImage;