import React, {FC, useEffect, useState} from "react";
import Modal from "../Modal";
import {ModalProps} from "../Modal/Modal";
import {createPaymentScheduleProvider} from "../../../../di";
import {PaymentSchedule} from "../../../../api-client";
import {useErrorCallback, useLoading} from "../../../../hooks";
import {RenderPaymentSchedule} from "../../../pages/detailed-project/RenderPaymentSchedule";

import styles from "./PaymentScheduleModal.module.scss";

interface Props extends ModalProps {
  projectUuid: string;
}

export const PaymentScheduleModal: FC<Props> = ({active, onClose, projectUuid}) => {
  const [paymentSchedule, setPaymentSchedule] = useState<PaymentSchedule | null>(null);
  const errorCallback = useErrorCallback();
  const [loading, withLoading] = useLoading();
  useEffect(() => {
    const fetchProjectAndSchedule = async () => {
      try {
        const manager = await createPaymentScheduleProvider();
        const result = await manager.getPaymentSchedule(projectUuid);
        setPaymentSchedule(result);
      } catch (err: any) {
        await errorCallback(err);
      }
    };

    (async () => {
      await withLoading(fetchProjectAndSchedule);
    })();
  }, [projectUuid]);
  if (!paymentSchedule ) return null;
  return (
    <Modal active={active} onClose={onClose} className={styles.container}>
      <Modal.Close/>
      <div className={styles.paymentSchedule}>
        <header className={styles.paymentSchedule__header}>
          <div className={styles.paymentSchedule__headerProjectInfo}>
            <p className={styles.paymentSchedule__headerProjectInfoTitle}>Данные проекта:
              <span className={styles.paymentSchedule__headerProjectInfoName}> {paymentSchedule.projectInfo.name[0][0]?.text}</span>
              <span className={styles.paymentSchedule__headerProjectInfoID}>#{paymentSchedule.projectInfo.paymentCode}</span></p>
            <p className={styles.paymentSchedule__headerProjectInfoTitle}>Данные заемщика: <span
              className={styles.paymentSchedule__headerProjectInfoName}> {paymentSchedule.borrowerInfo.name} / ИНН {paymentSchedule.borrowerInfo.inn} </span>
            </p>
          </div>
          <div className={styles.paymentSchedule__headerContainer}>
            <p className={styles.paymentSchedule__headerTitle}>График выплат по договору <span>#????</span></p>
            <p className={styles.paymentSchedule__headerExecution}>Исполнение графика </p>
          </div>
        </header>

        <RenderPaymentSchedule paymentSchedule={paymentSchedule}/>
      </div>
    </Modal>
  );
};