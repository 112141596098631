import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";

import {Money} from "api-client";
import {useAppDispatch, useErrorCallback, useProfileData} from "hooks";
import {cn} from "utils";
import {createProfileManager, createStatsProvider} from "di";
import {
  AccountNotReadyWarningSection,
  Balance,
  PlannedIndicators,
  PortfolioStatistics,
  UpcomingReceipts
} from "components/pages";
import {Container, Money as MoneyComponent, Section} from "components";
import DonutChart from "components/pages/my-money/DonutChart";
import {set as setMoneyData} from "store/my-money";
import {set as setProfile} from "store/profile";

import styles from './MyMoney.module.scss';


const MyMoney = () => {
  const [loading, setLoading] = useState(true);
  const profileData = useProfileData();
  const handleError = useErrorCallback();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const onMount = async () => {
      try {
        await initMyMoneyData();
      } catch (error) {
        if (error instanceof Error) {
          await handleError(error);
        }
      } finally {
        setLoading(false);
      }
    };
    (async () => {
      await onMount();
    })();
  }, []);

  const initMyMoneyData = async () => {
    const statsProvider = await createStatsProvider();
    const myMoneyData = await statsProvider.getStats();
    dispatch(setMoneyData(myMoneyData));
    const manager = await createProfileManager();
    const profile = await manager.get();
    dispatch(setProfile(profile));
  };

  const chartData = [
    {name: 'Свободно', value: 0, color: '#BFFFD2'},
    {name: 'Бронировано', value: 0, color: '#22A873'},
    {name: 'В сделках', value: 0, color: '#68A4FE'},
  ];

  return (
    <>
      <AccountNotReadyWarningSection/>
      <Outlet/>
      {profileData.lenderVerified && <Section>
        <Container>
          <div className={styles.myMoney__grid}>
            <div className={cn(styles.myMoney__balance, styles.myMoney__block)}><Balance/></div>
            <div className={cn(styles.myMoney__average, styles.myMoney__block)}><PlannedIndicators/></div>
            <div className={cn(styles.myMoney__totalFunds, styles.myMoney__block)}><DonutChart data={chartData}/></div>
            <div className={cn(styles.myMoney__upcomingPayments, styles.myMoney__block)}><UpcomingReceipts/></div>
            <div className={cn(styles.myMoney__currentDeals, styles.myMoney__block)}>
              <div>Сейчас сделок на платформе</div>
              <div className={styles.myMoney__currentDeals__sumTitle}>
                <p><span>1</span> займ </p>
                <p> на сумму</p>
              </div>
              <div className={styles.myMoney__currentDeals__sum}>
                <MoneyComponent money={new Money(5000000000, 'RUB')}/>
              </div>
            </div>
            <div className={cn(styles.myMoney__underReview, styles.myMoney__block)}>
              <div>Находятся на рассмотрении</div>
              <div className={styles.myMoney__underReview__sumTitle}>
                <p><span>7</span> займов </p>
                <p> на сумму</p>
              </div>
              <div className={styles.myMoney__underReview__sum}>
                <MoneyComponent money={new Money(14000000000, 'RUB')}/>
              </div>
            </div>
            <div className={cn(styles.myMoney__portfolioStats, styles.myMoney__block)}><PortfolioStatistics/></div>
            <div className={cn(styles.myMoney__faq, styles.myMoney__block)}>FAQ</div>
          </div>
        </Container>
      </Section>}
    </>
  );
};

export default MyMoney;


