import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {DetailedProject as DetailedProjectComponent, Section} from "components";
import {Container} from "../../../../../components";
import {DetailedProject as DetailedProjectModel, PaymentSchedule} from "api-client";
import {createPaymentScheduleProvider, createProjectProvider} from "di";
import {useErrorCallback, useLoading} from "hooks";
import './DetailedProject.scoped.scss';

const DetailedProject = () => {
  const {uuid} = useParams();
  const errorCallback = useErrorCallback();
  const [loading, withLoading] = useLoading();
  const [project, setProject] = useState<DetailedProjectModel | null>(null);
  const [paymentSchedule, setPaymentSchedule] = useState<PaymentSchedule | null>(null);
  useEffect(() => {
    const fetchProjectAndSchedule = async () => {
      try {
        const provider = await createProjectProvider();
        const project = await provider.getOne(uuid!);
        setProject(project);

        if (project) {
          const manager = await createPaymentScheduleProvider();
          const result = await manager.getPaymentSchedule(project.uuid);
          setPaymentSchedule(result);
        }
      } catch (err: any) {
        await errorCallback(err);
      }
    };

    (async () => {
      await withLoading(fetchProjectAndSchedule);
    })();
  }, [uuid]);
  return (
    <Container>
      <Section>
        { loading && <div>Загрузка...</div> }
        { !loading && project === null && <div>Проект не найден</div> }
        { project && <DetailedProjectComponent project={project} paymentSchedule={paymentSchedule}/> }
      </Section>
    </Container>
  );
};

export default DetailedProject;