import {PieChart, Pie, Cell, Tooltip} from 'recharts';
import React from "react";
import {Money} from "../../../common";
import {formatPercents} from "../../../../formatters";
import styles from './DonutChart.module.scss';

interface Data {
    name: string;
    value: number;
    color: string;
}

const DonutChart = ({data}: { data: Data[] }) => {
  const total = data.reduce((sum, item) => sum + item.value, 0);

  return (
    <div>
      <Money money={{amount: total, currencyCode: 'RUB'}} />
      <p>всего денег на платформе</p>
      <div className={styles.chartContainer}>
        <PieChart width={120} height={120}>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            innerRadius={30}
            outerRadius={60}
            fill="#8884d8"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color}/>
            ))}
          </Pie>
          <Tooltip formatter={(value: any) => `${value.toLocaleString('ru-RU')} ₽`}/>
        </PieChart>
        <div>
          {data.map((entry, index) => (
            <div key={index} className={styles.legendItem}>
              <div
                className={styles.colorIndicator}
                style={{backgroundColor: entry.color}}
              ></div>
              <div className={styles.legendText}>
                <div>
                  {entry.name} ({formatPercents((entry.value / total) * 100)})
                </div>
                <Money money={{amount: entry.value, currencyCode: 'RUB'}} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DonutChart;