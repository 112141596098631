import {Money} from "../../../../api-client";
import styles from "./Balance.module.scss";
import {Money as MoneyComponent} from "../../../common";
import React from "react";

export const Balance = () => {
  const data = [
    {label: 'Зачислено', value: new Money(0, 'RUB')},
    {label: 'Инвестировано', value: new Money(0, 'RUB')},
    {label: 'Возвращено', value: new Money(0, 'RUB')},
    {label: 'Выведено', value: new Money(0, 'RUB')},
    {label: 'Баланс', value: new Money(0, 'RUB')},
    {label: 'Бронировано', value: new Money(0, 'RUB')},
  ];

  return (
    <div className={styles.balance}>
      <h2 className={styles.balance__title}>Баланс счета</h2>
      <ul className={styles.balance__list}>
        {data.map((item, index) => (
          <li key={index} className={styles.balance__item}>
            <span className={styles.balance__label}>{item.label}</span>
            <MoneyComponent money={item.value}/>
          </li>
        ))}
      </ul>
      <div className={styles.balance__free}>
        <span>Свободно</span>
        <div className={styles.balance__freeValue}><MoneyComponent money={new Money(0, 'RUB')}/></div>
      </div>
    </div>
  );
};