import React, {ChangeEvent} from "react";
import fileConfig from 'configs/file';
import {UploadedFile} from "api-client";
import {createUploadManager} from "di";

type Props = {
    files: Array<UploadedFile>;
    addCallback: any;
    removeCallback: any;
    multiple?: boolean;
    label?: string;
    maxFileSize?: number;
}

let id = 0;

//TODO: VALIDATE FILE SIZE AND EXTENSION
const FileUpload = ({files, addCallback, removeCallback, multiple = true, label = "Файл", maxFileSize = fileConfig.fileMaxSize}: Props) => {
  const uid = () => {
    return `file-upload-${++id}`;
  };
  const uploadFiles = async (e: { target : HTMLInputElement}) => {
    const manager = await createUploadManager();
    const filesToUpload = Array.from(e.target.files || []);
    let i = 1;
    for (const file of filesToUpload) {
      // const uploadedFile = await manager.uploadFile(file);
      const uploadedFile = new UploadedFile(`file${i++}`, "123", "456");
      addCallback(uploadedFile);
    }
  };
  const removeFile = (index: number) => {
    removeCallback(index);
  };
  const filesList = () => {
    return files.map((file, index) =>
      <li key={file.name}>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            {file.name}
          </div>
          <div>
            <button type="button" onClick={() => removeFile(index)}>
              X
            </button>
          </div>
        </div>
        <div>
          {file.url}
        </div>
      </li>
    );
  };
  return (
    <>
      <div>
        <div>
          <label htmlFor={uid()}>
            {label}
          </label>
        </div>
        <input
          id={uid()}
          type="file"
          title=""
          multiple={multiple}
          onChange={(e: ChangeEvent<HTMLInputElement>) => uploadFiles(e)}
        />
      </div>
      <div>
        {filesList()}
      </div>
    </>
  );
};

export default FileUpload;