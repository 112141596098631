import {PaymentSchedule, PaymentStatus, translatePaymentStatusType} from "../../../../api-client";
import processing from "assets/images/svg/processing.svg";
import completed from "assets/images/complited.png";
import partial from "assets/images/partial.png";
import overdue from "assets/images/svg/overdue.svg";
import upcoming from "assets/images/svg/upcoming.svg";
import {formatDate} from "../../../../formatters";
import React from "react";
import {Money, Image} from "../../../common";
import {now} from "moment";
import styles from './RenderPaymentSchedule.module.scss';

interface Props {
    paymentSchedule: PaymentSchedule | null;
}
export const RenderPaymentSchedule = ({paymentSchedule}: Props) => {
  const renderStatus = (status: PaymentStatus, isLast: boolean, isProcessing: boolean) => {
    const imageSrc = (() => {
      if (isProcessing) return processing;
      else switch (status) {
      case PaymentStatus.PAID_ON_TIME:
        return completed;
      case PaymentStatus.NOT_PAID_OVERDUE:
        return partial;
      case PaymentStatus.PAID_OVERDUE:
        return overdue;
      case PaymentStatus.PAID_PARTIALLY:
        return overdue;
      case PaymentStatus.NOT_PAID_UPCOMING:
        return upcoming;
      default:
        return null;
      }
    })();

    return (
      <div className={styles.statusWrapper} style={{height: isLast ? "auto" : "50px"}}>
        <div className={styles.circle}>
          <Image src={imageSrc} alt={status} className={styles.statusImage} />
        </div>
      </div>
    );
  };
  if (!paymentSchedule) return null;
  else return <div className={styles.paymentSchedule__container}>
    <div className={styles.paymentSchedule__schedule}>
      <table className={styles.paymentSchedule__scheduleTable}>
        <thead>
          <tr>
            <th></th>
            <th>Дата</th>
            <th>Основной долг</th>
            <th>Процент</th>
            <th>Статус</th>
            <th>Размер исполнения</th>
            <th>Просрочка</th>
          </tr>
        </thead>
        <tbody>
          {paymentSchedule.payments.map((payment, index) => (
            <tr key={index}>
              <td>
                {renderStatus(payment.status, index === paymentSchedule.payments.length - 1, payment.isProcessing)}
              </td>
              <td>{formatDate(payment.date)}</td>
              <td><Money money={payment.principal}/></td>
              <td><Money money={payment.interest}/></td>
              <td>{translatePaymentStatusType(payment.status)}</td>
              <td><Money money={{
                amount: payment.actualPrincipal.amount +
                                payment.actualInterest.amount +
                                (payment.penalty ? payment.penalty.amount : 0), currencyCode: payment.actualPrincipal.currencyCode
              }}/></td>
              <td>{payment.overduePeriod}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    <div className={styles.paymentSchedule__footer}>
      <p className={styles.paymentSchedule__footerTitle}>Общий размер штрафных % на {formatDate(new Date(now()))}</p>
      <p className={styles.paymentSchedule__footerSum}><Money
        money={{
          amount: paymentSchedule.payments.reduce(
            (total, payment) => total + (payment.penalty?.amount || 0),
            0
          ),
          currencyCode:
                        paymentSchedule.payments[0]?.actualPrincipal.currencyCode || "RUB",
        }}
      /></p>
    </div>
  </div>;
};