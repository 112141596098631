import {NamedUrl} from "../types";
import {RouteDictionary} from "./RouteDictionary";

export const mainRoutes: Array<NamedUrl> = [
  {
    name: RouteDictionary.INDEX,
    url: "/"
  },
  {
    name: RouteDictionary.RULES_SERVICE,
    url: "/rules_service"
  },
  {
    name: RouteDictionary.RULES_AUTOINVEST,
    url: "/rules_autoinvest"
  },
  {
    name: RouteDictionary.PERSONAL_DATA_POLICY,
    url: "/personal_data_policy"
  },
  {
    name: RouteDictionary.RULES_REGISTRATION,
    url: "/rules_registration"
  },
  {
    name: RouteDictionary.RISK_DECLARATION,
    url: "/risk_declaration"
  },
  {
    name: RouteDictionary.ELECTRONIC_DOCUMENTS,
    url: "/electronic_documents"
  },
  {
    name: RouteDictionary.PERSONAL_POLICY_ACCEPTED,
    url: "/personal_policy_accepted"
  },
  {
    name: RouteDictionary.BORROWER_REQUIREMENTS,
    url: "/borrower-requirements"
  },
  {
    name: RouteDictionary.DOCUMENTS_LIST,
    url: "/documents-list"
  },
  {
    name: RouteDictionary.OVERDUE_DEBT_REGULATION,
    url: "/overdue_debt_regulation"
  },
  {
    name: RouteDictionary.FAQ,
    url: "/faq"
  },
  {
    name: RouteDictionary.PLATFORM,
    url: "/platform"
  },
  {
    name: RouteDictionary.PLATFORM_DOCUMENTS,
    url: `/platform-documents`
  },
  {
    name: RouteDictionary.ABOUT_US,
    url: "/about-us/actual"
  },
  {
    name: RouteDictionary.BORROWER_INFORMATION,
    url: "/borrower-information"
  },
  {
    name: RouteDictionary.INVESTORS_INFORMATION,
    url: "/investors-information"
  },
  {
    name: RouteDictionary.PUBLIC_DOCUMENTS_ACTUAL,
    url: "/about-us/actual"
  },
  {
    name: RouteDictionary.PUBLIC_DOCUMENTS_ARCHIVE,
    url: "/about-us/archive"
  },
  {
    name: RouteDictionary.NEWS,
    url: "/news"
  },
  {
    name: RouteDictionary.HOW_IT_WORKS,
    url: "/how-it-works"
  },
  {
    name: RouteDictionary.ENTER,
    url: "/enter"
  },
  {
    name: RouteDictionary.ENTER_BORROWER,
    url: "/enter-borrower"
  },
  {
    name: RouteDictionary.NOT_FOUND,
    url: "*"
  }
];