import React, {useContext, useEffect, useState} from "react";
import styles from './ProjectMainBorrower.module.scss';
import {DetailedProjectContext} from "../DetailedProjectContext/DetailedProjectContext";
import {
  OrderDetails,
  PaymentSchedule,
  TransactionType,
  translateProjectStatus,
  translateProjectType
} from "../../../../api-client";
import {Button, CustomLink, Image, Money, TertiaryButton} from "../../../common";
import {createOrdersProvider} from "../../../../di";
import {useErrorCallback, useLoading} from "../../../../hooks";
import apiClientConfig from "../../../../configs/app";
import {Environment, RouteDictionary} from "../../../../utils";
import ProjectMainStats from "../ProjectMainStats";
import {formatDate} from "../../../../formatters";
import ProjectProgress from "../../projects/ProjectProgress";
import {calculateCollectedPercentage} from "../../../../utils/pages";
import documentIcon from "assets/images/svg/document.svg";
import {TertiaryButtonColor} from "../../../common/buttons/decorators/TertiaryButton/TertiaryButton";
import {Link} from "react-router-dom";

interface Props {
  paymentSchedule: PaymentSchedule | null;
}

const ProjectMainBorrower = ({paymentSchedule}: Props) => {
  const project = useContext(DetailedProjectContext).project;
  const errorCallback = useErrorCallback();
  const [loading, withLoading] = useLoading();
  const [order, setOrder] = useState<OrderDetails>();
  let investmentFeePercent = 0;
  let dividendInterestFeePercent = 0;

  project.feeSettings.map((setting) => {
    if (setting.transactionType === TransactionType.INVESTMENT_FEE && setting.percent) investmentFeePercent = setting.percent;
    if (setting.transactionType === TransactionType.DIVIDEND_INTEREST_FEE && setting.percent) dividendInterestFeePercent = setting.percent;
  });

  useEffect(() => {
    const callback = async () => {
      try {
        const provider = await createOrdersProvider();
        const order = await provider.getOne(project.uuid!);
        setOrder(order);
      } catch (error) {
        if (error instanceof Error) {
          await errorCallback(error);
        }
      }
    };
    (async () => {
      await withLoading(callback);
    })();
  }, []);


  const devAndTest = (apiClientConfig.applicationEnvironment === Environment.dev)
        || (apiClientConfig.applicationEnvironment === Environment.test);

  if (loading) return <div>Загружаем...</div>;
  return (<>
    <div className={styles.detailedProjectMain}>
      <div className={styles.detailedProjectMain__top}>
        <div className={styles.detailedProjectMain__status}>
          <CustomLink to={RouteDictionary.DETAILED_PROJECT} params={{uuid: project.uuid}} className={styles.detailedProjectMain__code}>
            {project.paymentCode}
          </CustomLink>
          <div>
            {translateProjectStatus(project.status)}
          </div>
          <div className={styles.detailedProjectMain__code}>
            Тип: {translateProjectType(project.projectType)}
          </div>
        </div>
      </div>
      <div className={styles.detailedProjectMain__project}>
        <div>
          <p className={styles.detailedProjectMain__name}>
            {project.name[0].text}
          </p>
          <p className={styles.detailedProjectMain__info}>
            Срок действия инвестиционного предложения
            с {formatDate(project.investOfferDate)} до {formatDate(project.maturityDate)}
          </p>
        </div>
        {devAndTest && <div>
          <div className={styles.detailedProjectMain__oredrsSum}>
            <span className={styles.detailedProjectMain__oredrsSum__text}>Сумма ваших заявок: </span>
            <Money money={order?.investment || {amount: 0, currencyCode: 'RUB'}}/>
          </div>
        </div>}
      </div>

    </div>
    <ProjectMainStats/>
    <div className={styles.detailedProjectMain}>
      <div className={styles.detailedProjectMain__statusName}>В процессе сбора средств</div>
      <div className="detailed-project-invest__progress">
        <ProjectProgress
          percentage={calculateCollectedPercentage(project.accounting.investedSum, project.accounting.loan)}
          sum={project.accounting.investedSum} isPercent={false}/>
      </div>
    </div>

    <div className={styles.detailedProjectMain}>
      <div className={styles.paymentSchedule}>
        <table>
          <thead>
            <tr>
              <th>Дата платежа</th>
              <th>Выплата процентов</th>
              <th>Выплата основного долга</th>
              <th>Дата погашения</th>
            </tr>
          </thead>
          <tbody>
            {paymentSchedule && paymentSchedule.payments.map((payment, index) => (
              <tr key={index}>
                <td>{formatDate(payment.date)}</td>
                <td><Money money={payment.interest}/></td>
                <td><Money money={payment.principal}/></td>
                <td>-</td>
              </tr>
            ))}
          </tbody>
        </table>
        <TertiaryButton expanded color={TertiaryButtonColor.GRAY}>
          <Button className={styles.paymentSchedule__invoice}>Сформировать Invoice</Button>
        </TertiaryButton>
      </div>
    </div>

    <div className={styles.detailedProjectMain}>
      <div className={styles.documents}>
        {project.documents && project.documents.map((doc, index) => (
          <div key={index} className={styles.document}>
            <Image src={documentIcon}/>
            <div className={styles.document__info}>
              <Link to={doc.file.url} target={'_blank'} className={styles.document__link}>
                {doc.file.name}
              </Link>
              <span>{formatDate(doc.publicationDate)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  </>
  );
};

export default ProjectMainBorrower;