import React from "react";
import {Money} from "../../../../api-client";
import {Money as MoneyComponent} from "../../../common";
import styles from "./UpcomingReceipts.module.scss";

export const UpcomingReceipts = () => {
  const data = [
    {period: '30 дней', amount: new Money(0, 'RUB')},
    {period: '60 дней', amount: new Money(0, 'RUB')},
    {period: '90 дней', amount: new Money(0, 'RUB')},
  ];

  return (
    <div className={styles.receipts}>
      <h3 className={styles.receipts__title}>Ближайшие поступления</h3>
      <ul className={styles.receipts__list}>
        {data.map((item, index) => (
          <li key={index} className={styles.receipts__item}>
            <span className={styles.receipts__period}>{item.period}</span>
            <MoneyComponent money={item.amount}/>
          </li>
        ))}
      </ul>
    </div>
  );
};