import React from "react";
import {formatPercents} from "../../../../formatters";
import styles from "./PlannedIndicators.module.scss";

export const PlannedIndicators = () => {
  return (
    <div className={styles.indicators}>
      <div className={styles.indicators__container}>
        <div className={styles.indicators__list}>
          <span className={styles.indicators__title}>Средневзвешенные плановые показатели</span>
          <div className={styles.indicators__item}>
            <span className={styles.indicators__value}>0 мес</span>
            <span className={styles.indicators__label}>срок займа</span>
          </div>
          <div className={styles.indicators__item}>
            <span className={styles.indicators__value}>{formatPercents(0, 0)}</span>
            <span className={styles.indicators__label}>ставка договора</span>
          </div>
          <div className={styles.indicators__item}>
            <span className={styles.indicators__value}>{formatPercents(0, 0)}</span>
            <span className={styles.indicators__label}>эффективная доходность</span>
          </div>
        </div>
        <div >
          <span className={styles.indicators__title}>Фактические показатели</span>
          <div className={styles.indicators__note}>*расчет показателей осуществляется только после 6 месяцев инвестирования</div>
        </div>
      </div>

    </div>
  );
};