import React, {useState} from "react";
import {Container, Section} from "../../../components";
import {Page} from "../../../api-client";
import {createPageProvider} from "../../../di";
import {useEffectOnMount} from "../../../hooks";

const DocumentsList = () => {
  const [page, setPage] = useState<Page | null>(null);
  const [error, setError] = useState<string | null>(null);
  const loadPage = async () => {
    try {
      const provider = await createPageProvider();
      setPage(await provider.getOne('documents-list'));
    } catch (e) {
      setError('Ошибка загрузки страницы');
    }
  };

  useEffectOnMount(() => {
    loadPage();
  });

  const renderPage = () => {
    return (page ? <div dangerouslySetInnerHTML={{__html: page?.content}}></div> : <div/>);
  };

  return (
    <Section>
      <Container>
        список документов появится в ближайшее время
      </Container>
    </Section>
  );
};

export default DocumentsList;