import React, {FC, useContext, useEffect, useState} from "react";
import MarketNavigation from "../../../../../views/dashboard/lender/investment/projects/MarketNavigation";
import ProjectsHeaderOptions from "../ProjectsHeaderOptions";
import './ProjectsHeader.scoped.scss';
import {ProjectsContext} from "../../../../../views/dashboard/lender/investment/projects/ProjectsContext";
import TertiaryButton, {TertiaryButtonColor} from "../../../../common/buttons/decorators/TertiaryButton/TertiaryButton";
import {Button} from "../../../../common";
import {cn} from "../../../../../utils";
import {ProjectStatus, statusCount, statusInFastFilter, StatusKey} from "../../../../../api-client";
import {ProjectsCount} from "../../../../../api-client/models/project/ProjectsCount";

interface Props {
  projectsCount: ProjectsCount
}

const ProjectsHeader: FC<Props> = ({projectsCount}) => {
  const context = useContext(ProjectsContext);
  const [statusFilter, setStatusFilter] = useState<ProjectStatus[]>([]);
  const handleSearch = (search: string): void => {
    context.setFilter({...context.filter, search: search});
  };
  const handleClick = (statuses: ProjectStatus[]) => {
    const hasStatuses = statuses.some(status => statusFilter.includes(status));
    if (hasStatuses) {
      const newStatusFilter = statusFilter.filter(status => !statuses.includes(status));

      setStatusFilter(newStatusFilter);
    } else {
      setStatusFilter(statuses);
    }
  };
  useEffect(() => {
    context.setFilter({...context.filter, statuses: statusFilter});
  }, [statusFilter]);

  return (
    <div className="projects-header-items">
      <div className="projects-header-items__status-container">
        {Object.keys(statusInFastFilter).map((key: string, index) => {
          return <TertiaryButton color={TertiaryButtonColor.WHITE} key={index}>
            <Button className={cn("projects-header-items__status", 
              `${statusFilter.some((element:ProjectStatus)=> statusInFastFilter[key as StatusKey].includes(element)) 
                ? 'active' : ''}`)} onClick={() => handleClick(statusInFastFilter[key as StatusKey])}>
              {key} ({projectsCount[statusCount[key as StatusKey]]})
            </Button>
          </TertiaryButton>;
        })}
        <TertiaryButton color={TertiaryButtonColor.WHITE}>
          <Button className="projects-header-items__status">
            Просрочка (0)
          </Button>
        </TertiaryButton>
      </div>
      <MarketNavigation />
      <ProjectsHeaderOptions search={context.filter.search} handleSearch={handleSearch} />
    </div>
  );
};

export default ProjectsHeader;