import React from "react";
import {Money as MoneyComponent} from "../../../common";
import {Money} from "../../../../api-client";
import styles from "./PortfolioStatistics.module.scss";

export const PortfolioStatistics = () => {
  const rows = [
    {status: "В графике", projects: 0, investment: 0, returned: 0, balance: 0, income: 0},
    {status: "В просрочке", projects: 0, investment: 0, returned: 0, balance: 0, income: 0},
    {status: "Возвращено", projects: 0, investment: 0, returned: 0, balance: 0, income: 0},
    {status: "В дефолте", projects: 0, investment: 0, returned: 0, balance: 0, income: 0},
    {status: "Инкассо", projects: 0, investment: 0, returned: 0, balance: 0, income: 0},
    {status: "Списание", projects: 0, investment: 0, returned: 0, balance: 0, income: 0},
    {status: "Итого", projects: 0, investment: 0, returned: 0, balance: 0, income: 0},
  ];

  return (
    <div>
      <h3 className={styles.statistics__title}>Статистика портфеля</h3>
      <div className={styles.statistics__table}>
        <div className={styles.statistics__header}>
          <div>Статус инвестиции</div>
          <div>Проектов</div>
          <div>Сумма инвестиции</div>
          <div>Возвращено ОД</div>
          <div>Остаток ОД</div>
          <div>Доход</div>
        </div>
        {rows.map((row, index) => (
          <div className={styles.statistics__row} key={index}>
            <div>{row.status}</div>
            <div>{row.projects}</div>
            <div>{row.investment === 0? '—': <MoneyComponent money={new Money(row.investment, 'RUB')}/>}</div>
            <div>{row.returned === 0? '—': <MoneyComponent money={new Money(row.returned, 'RUB')}/>}</div>
            <div>{row.balance === 0? '—': <MoneyComponent money={new Money(row.balance, 'RUB')}/>}</div>
            <div>{row.income === 0? '—': <MoneyComponent money={new Money(row.income, 'RUB')}/>}</div>
          </div>
        ))}
      </div>
    </div>
  );
};