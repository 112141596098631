import React, {FC, useEffect, useState} from "react";
import './MyProjectsHeader.scoped.scss';
import MyProjectsNavigation from "../MyProjectsNavigation";
import {Button, Money} from "../../../common";
import {
  Money as MoneyModel,
  ProjectStatus,
  statusCount,
  statusInFastFilterMyInvestments,
  StatusKeyMyInvestments
} from "../../../../api-client";
import TertiaryButton from "../../../common/buttons/decorators/TertiaryButton";
import {TertiaryButtonColor} from "../../../common/buttons/decorators/TertiaryButton/TertiaryButton";
import {cn} from "../../../../utils";
import {ProjectsCount} from "../../../../api-client/models/project/ProjectsCount";
import {Filter} from "../my-investments/MyInvestments/MyInvestments";

interface Props {
    invested: MoneyModel;
    projectsCount: ProjectsCount,
    setFilter: (filter: Filter) => void;
}

const MyProjectsHeader: FC<Props> = ({invested, projectsCount, setFilter}) => {
  const [statusFilter, setStatusFilter] = useState<ProjectStatus[]>([]);
  const handleClick = (statuses: ProjectStatus[]) => {
    const hasStatuses = statuses.some(status => statusFilter.includes(status));
    if (hasStatuses) {
      const newStatusFilter = statusFilter.filter(status => !statuses.includes(status));

      setStatusFilter(newStatusFilter);
    } else {
      setStatusFilter(statuses);
    }
  };
  useEffect(() => {
    setFilter({statuses: statusFilter});
  }, [statusFilter]);
  return (
    <div className="my-projects__block-header">
      <div className="my-projects-header-items__status-container">
        {Object.keys(statusInFastFilterMyInvestments).map((key: string, index) => {
          return <TertiaryButton color={TertiaryButtonColor.WHITE} key={index}>
            <Button className={cn("my-projects-header-items__status",
              `${statusFilter.some((element: ProjectStatus) => statusInFastFilterMyInvestments[key as StatusKeyMyInvestments].includes(element))
                ? 'active' : ''}`)} onClick={() => handleClick(statusInFastFilterMyInvestments[key as StatusKeyMyInvestments])}>
              {key} ({projectsCount[statusCount[key as StatusKeyMyInvestments]]})
            </Button>
          </TertiaryButton>;
        })}
        <TertiaryButton color={TertiaryButtonColor.WHITE}>
          <Button className="my-projects-header-items__status">
            Просрочка (0)
          </Button>
        </TertiaryButton>
      </div>
      <MyProjectsNavigation/>
      <div className="my-projects__collected">
        Общая сумма инвестиций: <Money money={invested}/>
      </div>
    </div>
  );
};

export default MyProjectsHeader;